import React, { useState, useEffect } from "react";
import axios from "axios";
import "./history.scss";
import Logo from "../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png";
import { useNavigate } from "react-router-dom";
import defaultAvatar from "../assets/images.jpg";

const History = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [error, setError] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderHistory = async () => {
      try {
        const response = await axios.get("https://thepartnersapi.jetsonweb.com/api/partner/order-history", {
          withCredentials: true,
        });
        console.log("Order History:", response.data);
        setOrders(response.data);
        setFilteredOrders(response.data);
      } catch (err) {
        console.error("Error fetching order history:", err);
        setError("No order history available.");
      }
    };

    fetchOrderHistory();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("https://thepartnersapi.jetsonweb.com/api/partners/profile", {
          withCredentials: true,
        });

        setUserInfo(response.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError("Could not retrieve user profile. Please try again.");
      }
    };

    fetchUserProfile();
  }, []);

  const handleDateChange = (event) => {
    const selected = event.target.value;
    setSelectedDate(selected);

    if (!selected) {
      setFilteredOrders(orders);
      return;
    }

    const filtered = orders.filter((order) =>
      new Date(order.created_at).toLocaleDateString() === new Date(selected).toLocaleDateString()
    );

    setFilteredOrders(filtered);
  };

  return (
    <div>
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a onClick={() => navigate("/Home")} className="nav-link">
            Home
          </a>
          <a onClick={() => navigate("/History")} className="nav-link">
            History
          </a>
          <a onClick={() => navigate("/Setting")} className="nav-link">
            Setting
          </a>
        </nav>
        <div className="profile-image">
          {userInfo?.profile_image ? (
            <img src={`https://thepartnersapi.jetsonweb.com${userInfo.profile_image}`} alt="Profile" className="profile-img" />
          ) : (
            <img src={defaultAvatar} alt="Default Profile" className="profile-img" />
          )}
        </div>
        <div className="profile-info">
          <h3>{userInfo ? userInfo.full_name : "Loading..."}</h3>
        </div>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Your Product Awaits</h1>
          <p>Experience excellence with our top-of-the-line roasted chickens. Easy ordering.</p>
        </div>
      </section>

      <section className="order-history">
        <h2>Order History</h2>

        <div className="filter-container">
          <label htmlFor="order-date">Filter by Date:</label>
          <input
            type="date"
            id="order-date"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>

        {filteredOrders.length === 0 ? (
          <div className="no-orders">
            <h3>No orders available on this date.</h3>
            <p>Try selecting a different date.</p>
          </div>
        ) : (
          <ul className="order-list">
            {filteredOrders.map((order) => (
              <li key={order.id} className="order-card">
                <div className="order-details">
                  <p><strong>Order ID:</strong> {order.id}</p>
                  <p><strong>Quantity:</strong> {order.quantity}</p>
                  <p><strong>Size:</strong> {order.size}</p>
                  <p><strong>Location:</strong> {order.location}</p>
                  <p><strong>Phone:</strong> {order.phone_number}</p>
                  <p><strong>Status:</strong> <span className={`status ${order.status.toLowerCase()}`}>{order.status}</span></p>
                  <p><strong>Details:</strong> {order.order_details}</p>
                  <p><strong>Date:</strong> {new Date(order.created_at).toLocaleDateString()}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </section>
    </div>
  );
};

export default History;
