import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import Login from "../src/login/Login"
import Signup from '../src/signup/Signup';
import Home from '../src/Home/Home'
import History from '../src/history/History';
import Pending from './pending/Pending';
import Forget from './forgetpass/Forget';
import ResetPassword from './resetpass/Resetpassword';
import Setting from './setting/Setting';
import Declined from './declined/Declined';
import Suspend from './sespend/Sespend.';

const App = () =>{
    return (
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />  {/* Route for Home */}
          <Route path="/Signup" element={<Signup />} />  {/* Route for Home */}
          <Route path="/Login" element={<Login />} />  {/* Route for Home */}
          <Route path="/Home" element={<Home />} />  {/* Route for Home */}
          <Route path="/History" element={<History />} />  {/* Route for Home */}
          <Route path="/Pending" element={<Pending />} />  {/* Route for Home */}
          <Route path="/Forget" element={<Forget />} />  {/* Route for Home */}
          <Route path="/ResetPassword" element={<ResetPassword />} />  {/* Route for Home */}
          <Route path="/Setting" element={<Setting />} />  {/* Route for Home */}
          <Route path="/Declined" element={<Declined />} />  {/* Route for Home */}
          <Route path="/Suspend" element={<Suspend />} />  {/* Route for Home */}
          
          {/* Add other routes here */}
        </Routes>
      </BrowserRouter>
    )
}
export default App;