import React, { useState } from 'react';
import './signup.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [location, setLocation] = useState('');
  const [locations, setLocations] = useState([]); // Array to store multiple locations

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setProfileImage(event.target.files[0]);
    }
  };

  const addLocation = () => {
    if (location.trim() !== '' && !locations.includes(location)) {
      setLocations([...locations, location]);
      setLocation('');
    }
  };

  const removeLocation = (index) => {
    setLocations(locations.filter((_, i) => i !== index));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');

    if (!fullName || !username || !companyName || !businessType || !email || !password || !confirmPassword || !profileImage || locations.length === 0) {
      setErrorMessage("All fields are required!");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }

    const formData = new FormData();
    formData.append('full_name', fullName);
    formData.append('username', username);
    formData.append('company_name', companyName);
    formData.append('business_type', businessType);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);
    formData.append('profile_image', profileImage);
    formData.append('location', JSON.stringify(locations));

    try {
      const response = await axios.post('https://thepartnersapi.jetsonweb.com/api/signup', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setSuccessMessage('Partner added successfully. Pending admin approval.');

      setFullName('');
      setUsername('');
      setCompanyName('');
      setBusinessType('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setProfileImage(null);
      setLocations([]);

      setTimeout(() => navigate("/Pending"), 2000);
    } catch (error) {
      setErrorMessage("Signup failed! Please try again.");
    }
  };


  return (
    <div className="signup-container">
      <div className="top">
        <div className="wrapper">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="form-containerS">
        <h2 className="title1">Create an Account</h2>
        <form onSubmit={handleSubmit}>
          <div className="image-picker-container">
            <div className="image-preview">
              {profileImage ? (
                <img src={URL.createObjectURL(profileImage)} alt="Profile" className="profileImagePreview" />
              ) : (
                <div className="image-placeholder">No Image</div>
              )}
            </div>
            <button type="button" className="chooseImageButton" onClick={() => document.getElementById('profileImage').click()}>
              Choose Image
            </button>
            <input id="profileImage" type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
          </div>

          <input type="text" className="input-field" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
          <input type="text" className="input-field" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="text" className="input-field" placeholder="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          <input type="email" className="input-field" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="text" className="input-field" placeholder="Business Type e.g. Hotel, Cafe, Restaurant" value={businessType} onChange={(e) => setBusinessType(e.target.value)} />
          <div className="password-container">
            <input type={showPassword ? 'text' : 'password'} className="input-field" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <button type="button" className="toggle-password" onClick={togglePasswordVisibility}>
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>

          <div className="password-container">
            <input type={showConfirmPassword ? 'text' : 'password'} className="input-field" placeholder="Confirm Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            <button type="button" className="toggle-password" onClick={toggleConfirmPasswordVisibility}>
              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>

          {/* Multiple Locations Input */}
          <div className="location-container">
            <input type="text" className="input-field" placeholder="Enter Location" value={location} onChange={(e) => setLocation(e.target.value)} />
            <button type="button" className="add-location-button" onClick={addLocation}>Add Location</button>
            {locations.length > 0 && (
              <select className="location-dropdown">
                {locations.map((loc, index) => (
                  <option key={index} value={loc}>{loc}</option>
                ))}
              </select>
            )}
            {locations.map((loc, index) => (
              <div key={index} className="location-item">
                <span>{loc}</span>
                <button type="button" className="remove-location-button" onClick={() => removeLocation(index)}>Remove</button>
              </div>
            ))}
          </div>

          <button type="submit" className="signup-button">Sign Up</button>
        </form>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {successMessage && <p className="success-message">{successMessage}</p>}

        <p className="footer-text">
          Already have an account? <a onClick={() => navigate("/Login")} className="link">Log in</a>
        </p>
      </div>
    </div>
  );
};

export default Signup;
