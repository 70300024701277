import React, { useState } from 'react';
import './login.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53.jpg'; // Update with the actual path to your logo
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons from react-icons
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making HTTP requests

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleHome = async (event) => {
    event.preventDefault();
    setErrorMessage(''); // Reset error message
  
    const payload = {
      email: inputEmail.trim(),
      password: inputPassword,
    };
  
    try {
      const response = await axios.post('https://thepartnersapi.jetsonweb.com/api/partners/login', payload, {
        withCredentials: true, // Include credentials for session handling
      });
  
      if (response.status === 200) {
        console.log("API Response:", response.data); // Debugging
  
        const approvalStatus = response.data.isApproved; // The status is now a number (1, 2, 3, or 0)
        console.log("Approval Status:", approvalStatus); // Debugging
  
        // Navigate based on approval status
        switch (approvalStatus) {
          case 1:
            navigate('/Home'); // Approved
            break;
          case 2:
            navigate('/Declined'); // Declined
            break;
          case 3:
            navigate('/Suspend'); // Suspended
            break;
          case 0:
            navigate('/Pending'); // Pending
            break;
          default:
            navigate('/Pending'); // Fallback if status is unknown
            break;
        }
      }
    } catch (error) {
      console.error("Login Error:", error.response?.data); // Debugging
      setErrorMessage(
        error.response?.data?.message || 'An unexpected error occurred. Please try again.'
      );
    }
  };
  

  const handleSignup = (event) => {
    event.preventDefault();
    navigate('/Signup');
  };

  const handleForgotPassword = (event) => {
    event.preventDefault();
    navigate('/Forget');
  };

  return (
    <div className="login-container">
      <div className="top">
        <div className="wrapper">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <div className="form-containerL">
        <h2 className="title">Login</h2>
        <form>
          <input
            type="email"
            className="input-field"
            placeholder="Email"
            value={inputEmail}
            onChange={(e) => setInputEmail(e.target.value)}
          />
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              className="input-field"
              placeholder="Password"
              value={inputPassword}
              onChange={(e) => setInputPassword(e.target.value)}
            />
            
            <button
              type="button"
              className="toggle-password"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button onClick={handleHome} type="submit" className="login-button">
            Log In
          </button>
          <p className="footer-text">
            Don't have an account?{' '}
            <a onClick={handleSignup} className="link">
              Sign up
            </a>
          </p>
          <p className="footer-text">
            <a onClick={handleForgotPassword} className="link">
              Forgot Password?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
