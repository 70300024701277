import React from 'react';
import './declined.scss'; // Import SCSS
import { useNavigate } from 'react-router-dom';
import { XCircle } from 'lucide-react'; // Import icon from lucide-react

const Declined = () => {
    const navigate = useNavigate(); 
    const handleNavigate = () => {
      navigate('/Login'); // Navigate to Login when the button is clicked
    };

  return (
    <div className="declined-page">
      <div className="declined-container">
        <XCircle className="declined-icon" size={60} /> {/* Error Icon */}
        <h1 className="declined-title">Access Denied</h1>
        <p className="declined-message">
          Unfortunately, your request has been declined. For more details, please contact us at{' '}
          <a href="mailto:info@jetsondoro.com" className="email-link">info@jetsondoro.com</a>.
        </p>
        <button onClick={handleNavigate} className="back-btn">Go Back</button>
      </div>
    </div>
  );
}

export default Declined;
