import React from "react";
import { useNavigate } from "react-router-dom";
import { AlertTriangle } from "lucide-react";
import { motion } from "framer-motion";
import "./suspend.scss"; // Import SCSS file

const Suspend = () => {
  const navigate = useNavigate();

  return (
    <div className="suspend-container">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        className="suspend-card"
      >
        <div className="suspend-content">
          <motion.div
            initial={{ scale: 0.8, rotate: -10 }}
            animate={{ scale: 1, rotate: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <AlertTriangle className="suspend-icon" />
          </motion.div>

          <h2 className="suspend-title">Account Suspended</h2>
          <p className="suspend-message">
            Your account has been suspended due to a policy violation. If you think this is an error, contact support.
          </p>

          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/Login")}
            className="suspend-button"
          >
            Go-Back
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default Suspend;
