import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png';
import defaultAvatar from '../assets/images.png'; 
import './setting.scss';
import { useNavigate } from 'react-router-dom'; 
import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Using axios to fetch data

const Setting = () => {
  const [userInfo, setUserInfo] = useState(null); 
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState(''); 
  const [message1, setMessage1] = useState('');// To display success or error messages
  const [selectedImage, setSelectedImage] = useState(null);
  const [locations, setLocations] = useState([]); // State for storing locations
  const [location, setLocation] = useState('');    // State for new location input

  const navigate = useNavigate(); 

  useEffect(() => {
    axios.get('https://thepartnersapi.jetsonweb.com/api/partners/profile', { withCredentials: true })
      .then(response => {
        setUserInfo(response.data);
        if (response.data) {
          setFullName(response.data.full_name); // Set the full name to the input field
          setEmail(response.data.email);       // Set the email to the input field
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setMessage('Error fetching user data.');
      });
  }, []);

  const handleReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    try {
      const response = await axios.put("https://thepartnersapi.jetsonweb.com/api/partners/update-password", { email, password: newPassword });
      setMessage(response.data.message);
      // setTimeout(() => navigate("/login"), 3000); // Redirect after success
    } catch (err) {
      setMessage(err.response?.data?.error || "An error occurred");
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file)); // Preview the selected image
    }
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (selectedImage) {
      const formData = new FormData();
      formData.append('profile_image', e.target.files[0]);
      try {
        const response = await axios.put("https://thepartnersapi.jetsonweb.com/api/partners/update-profile-image", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        setMessage('Profile image updated successfully!');
        setUserInfo(prevState => ({
          ...prevState,
          profile_image: response.data.profile_image, // Assuming the new image URL is in the response
        }));
      } catch (err) {
        setMessage(err.response?.data?.error || "Error updating profile image");
      }
    }
  };

  // Function to add a new location
  const addLocation = () => {
    if (location.trim() === '') {
      alert('Please enter a location!');
      return;
    }

    if (!locations.includes(location)) {
      setLocations([...locations, location]);
      setLocation(''); // Clear input field after adding location
    } else {
      alert('Location already exists!');
    }
  };

  // Function to remove a location
  const removeLocation = (index) => {
    const updatedLocations = locations.filter((_, i) => i !== index);
    setLocations(updatedLocations);
  };

  const saveLocations = async () => {
    if (!email || locations.length === 0) {
      setMessage("Please enter an email and add at least one location.");
      return;
    }
  
    try {
      const response = await fetch("https://thepartnersapi.jetsonweb.com/api/add-location", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email, // Send email instead of partnerId
          location: locations[locations.length - 1], // Send only the latest added location
        }),
      });
  
      const data = await response.json();
      if (response.ok) {
        setMessage1("Location added successfully!"); // Set success message
      } else {
        setMessage(`Error: ${data.error}`); // Set error message
      }
    } catch (error) {
      console.error("Error saving locations:", error);
      setMessage("An error occurred while saving locations."); // Set error message
    }
  };
  
  return (
    <div className="settings-page">
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a onClick={() => navigate('/Home')} className="nav-link">Home</a>
          <a onClick={() => navigate('/History')} className="nav-link">History</a>
          <a onClick={() => navigate('/Setting')} className="nav-link">Setting</a>
        </nav>
        <div className="profile-image">
          {userInfo && userInfo.profile_image ? (
            <img 
              src={`https://thepartnersapi.jetsonweb.com${userInfo.profile_image}`} 
              alt="Profile" 
              className="profile-img"
            />
          ) : (
            <img 
              src={defaultAvatar} 
              alt="Default Profile" 
              className="profile-img"
            />
          )}
        </div>
        <div className="profile-info">
          <h3>{userInfo ? userInfo.full_name : 'Loading...'}</h3>
        </div>
      </header>

      {/* Two-column settings section */}
      <div className="settings-container">
        {/* Password Change Section */}
        <div className="password-section">
          <label htmlFor="new-password">Email</label>
          <form onSubmit={handleReset}>
            <input
              type="email"
              value={email} // Controlled by the email state, which was fetched initially
              readOnly
            />
            
            <label htmlFor="new-password">New Password</label>
            <input
              type="text"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />

            <label htmlFor="confirm-password">Confirm Password</label>
            <input
              type="text"
              id="confirm-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />

            <button type="submit">Update Password</button>
          </form>

          {message && <p className="message">{message}</p>} {/* Display message */}
        </div>

        {/* Location Management Section */}
       
      </div>
      <div className="location-container1">
      <h1>Add more locations</h1>

      {/* Display success message after database update */}
      {message1 && <p className="message">{message1}</p>}  {/* Success Message */}

      {/* Input field for entering a location */}
      <form onSubmit={(e) => { e.preventDefault(); addLocation(); }} className="location-form">
        <input
          type="text"
          className="input-field"
          placeholder="Enter Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required
        />

        {/* Add Location Button */}
        <button type="submit" className="add-location-button">
          Add Location
        </button>

        {/* Dropdown to display locations */}
        {locations.length > 0 && (
          <select className="location-dropdown">
            {locations.map((loc, index) => (
              <option key={index} value={loc}>
                {loc}
              </option>
            ))}
          </select>
        )}

        {/* Display list of locations with Remove button */}
        <div className="saved-locations">
          {locations.map((loc, index) => (
            <div key={index} className="location-item">
              <span>{loc}</span>
              <button
                type="button"
                className="remove-location-button"
                onClick={() => removeLocation(index)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>

        {/* Button to save locations to the partners table */}
        <button type="button" className="save-locations-button" onClick={saveLocations}>
          Save Locations
        </button>
      </form>
    </div>
    </div>
    
  );
};

export default Setting;
